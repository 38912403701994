define("discourse/plugins/discourse-jira/discourse/components/modal/attach", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/object", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _object, _dButton, _dModal, _ajax, _ajaxError, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _Attach;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Attach = _exports.default = (_class = (_Attach = class Attach extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "loading", _descriptor, this);
      _initializerDefineProperty(this, "issueKey", _descriptor2, this);
      _initializerDefineProperty(this, "issueUrl", _descriptor3, this);
      _defineProperty(this, "topicId", this.args.model.topic_id);
      _defineProperty(this, "postNumber", this.args.model.post_number);
    }
    get disabled() {
      return this.loading || !this.issueKey;
    }
    async attachIssue() {
      try {
        this.loading = true;
        const result1 = await (0, _ajax.ajax)("/jira/issues/attach", {
          type: "POST",
          data: {
            issue_key: this.issueKey,
            topic_id: this.topicId,
            post_number: this.postNumber
          }
        });
        this.issueKey = result1.issue_key;
        this.issueUrl = result1.issue_url;
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.loading = false;
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <DModal
        @title={{i18n "discourse_jira.attach_issue"}}
        @closeModal={{@closeModal}}
      >
        <:body>
          {{#if this.issueUrl}}
            <p>
              {{i18n
                "discourse_jira.issue_creation_success"
                issueKey=this.issueKey
              }}
            </p>
            <p><a href={{this.issueUrl}}>{{this.issueKey}}</a></p>
          {{else}}
            <div class="form">
              <section class="field">
                <section class="field-item">
                  <label>{{i18n "discourse_jira.attach_form.issue_key"}}</label>
                  <Input
                    @value={{this.issueKey}}
                    class="jira-key"
                    autofocus="autofocus"
                  />
                </section>
              </section>
            </div>
          {{/if}}
        </:body>
        <:footer>
          {{#if this.issueUrl}}
            <DButton
              class="btn-primary"
              @action={{@closeModal}}
              @label="discourse_jira.attach_form.continue"
            />
          {{else}}
            <DButton
              class="btn-primary"
              @action={{this.attachIssue}}
              @label="discourse_jira.attach_issue"
              @disabled={{this.disabled}}
              @isLoading={{this.loading}}
            />
          {{/if}}
        </:footer>
      </DModal>
    
  */
  {
    "id": "WrOUNuq1",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_jira.attach_issue\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"issueUrl\"]],[[[1,\"          \"],[10,2],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"discourse_jira.issue_creation_success\"],[[\"issueKey\"],[[30,0,[\"issueKey\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,2],[12],[10,3],[15,6,[30,0,[\"issueUrl\"]]],[12],[1,[30,0,[\"issueKey\"]]],[13],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"form\"],[12],[1,\"\\n            \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n              \"],[10,\"section\"],[14,0,\"field-item\"],[12],[1,\"\\n                \"],[10,\"label\"],[12],[1,[28,[32,1],[\"discourse_jira.attach_form.issue_key\"],null]],[13],[1,\"\\n                \"],[8,[32,2],[[24,0,\"jira-key\"],[24,\"autofocus\",\"autofocus\"]],[[\"@value\"],[[30,0,[\"issueKey\"]]]],null],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"issueUrl\"]],[[[1,\"          \"],[8,[32,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,1],\"discourse_jira.attach_form.continue\"]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\",\"@isLoading\"],[[30,0,[\"attachIssue\"]],\"discourse_jira.attach_issue\",[30,0,[\"disabled\"]],[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-jira/discourse/components/modal/attach.js",
    "scope": () => [_dModal.default, _i18n.default, _component2.Input, _dButton.default],
    "isStrictMode": true
  }), _Attach), _Attach), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "issueKey", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "issueUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "attachIssue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "attachIssue"), _class.prototype)), _class);
});